<app-breadcrumb [title]="'Profile'" [breadcrumb]="'Profile'"></app-breadcrumb>
<!-- personal deatail section start -->
<section class="contact-page pt-1 register-page">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="card text-dark bg-light mb-3">
                    <div class="card-header">
                        <div class="row">
                            <div class="col d-grid align-items-center"><b class="fs-5"> Datos personales</b></div>
                            <div class="col d-flex justify-content-end">
                                <button *ngIf="showBtnSave" class="btn btn-sm btn-solid" (click)="updateUser()"
                                    type="submit">Guardar</button>
                                <ng-container *ngIf="user.direction.length > 0">
                                    <button [hidden]="showNewAddress" (click)="newAddress()" class="btn btn-info mx-3"
                                        [innerText]="'Nueva dirección' "></button>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                    <div class="card-body">
                        <form class="">
                            <div class="row">
                                <div class="col-md-4">
                                    <label for="name">Nombres</label>
                                    <input type="text" class="form-control form-control-sm" [(ngModel)]="user.firstName"
                                        name="name" id="name" placeholder="Ingrese sus nombres">
                                </div>
                                <div class="col-md-4">
                                    <label for="email">Apellidos</label>
                                    <input type="text" class="form-control form-control-sm" [(ngModel)]="user.lastName"
                                        id="last-name" name="last-name" placeholder="Ingrese sus apellidos">
                                </div>
                                <div class="col-md-4">
                                    <label for="email">E-mail</label>
                                    <input [readOnly]="true" type="text" class="form-control form-control-sm"
                                        [(ngModel)]="user.email" id="email" name="email" placeholder="Email">


                                </div>
                                <div class="col-xl-4 col-lg-6 col-md-12 col-sm-12">
                                    <label [class]="user.verificate_phone ? 'text-success' : 'text-danger'"
                                        for="review">
                                        {{ user.verificate_phone ? 'Teléfono verificado' : 'Teléfono no verificado' }}
                                    </label>
                                    <div class="input-group">
                                        <div style="width: 4rem" class="input-group-prepend">
                                            <select [disabled]="user.verificate_phone"
                                                class="form-control form-control-sm" name="country" [(ngModel)]="code"
                                                (ngModelChange)="onCountryChange($event)">
                                                <option *ngFor="let country of countries"
                                                    [value]="country.callingCodes[0]">
                                                    + {{ country.callingCodes[0] }} &nbsp;&nbsp;&nbsp;&nbsp; {{
                                                    country.name }}</option>
                                            </select>
                                        </div>
                                        <input [disabled]="user.verificate_phone" type="text"
                                            class="form-control form-control-sm" [(ngModel)]="phone" id="review"
                                            name="review">
                                        <div *ngIf="!user.verificate_phone" class="input-group-append">
                                            <button class="btn btn-outline-secondary btn-sm"
                                                title="Enviar código de verificación"
                                                (click)="sendVerificationCode(phone)" type="button">Enviar
                                                código</button>
                                        </div>
                                        <div *ngIf="user.verificate_phone" class="input-group-append">
                                            <button class="btn btn-outline-secondary btn-sm" title="Cambiar Teléfono"
                                                (click)="changePhoneNumber()" type="button">Cambiar Teléfono</button>
                                        </div>
                                    </div>
                                </div>


                                <div *ngIf="send_verification_code && !this.user.verificate_phone"
                                    class="col-xl-4 col-lg-6 col-md-12 col-sm-12">
                                    <label for="code">Código de verificación</label>
                                    <div class="input-group">
                                        <input class="form-control form-control-sm" [(ngModel)]="codeVerificationCheck"
                                            mask="000000" [showMaskTyped]="true" id="code" name="code">
                                        <div class="input-group-append">
                                            <button class="btn btn-outline-info btn-sm"
                                                title="Verificar código de verificación"
                                                (click)="checkVerificationCode()" type="button">Verificar
                                                código</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div class="col-lg-12">
                <div class="card text-dark bg-light mb-3">
                    <div class="card-header"><b class="fs-5"> Datos de la empresa</b></div>
                    <div class="card-body">
                        <form class="">
                            <div class="row">
                                <div class="col-md-3">
                                    <label for="nameCommercial">Nombre comercial</label>
                                    <input type="text" class="form-control form-control-sm"
                                        [(ngModel)]="addressCompany.nameCommercial" name="nameCommercial"
                                        id="nameCommercial" placeholder="">
                                </div>
                                <div class="col-md-3">
                                    <label for="socialReason">Razón social</label>
                                    <input type="text" class="form-control form-control-sm"
                                        [(ngModel)]="addressCompany.socialReason" name="socialReason" id="socialReason"
                                        placeholder="">
                                </div>
                                <div class="col-md-3">
                                    <label for="nit">NIT</label>
                                    <input type="text" class="form-control form-control-sm"
                                        [(ngModel)]="addressCompany.nit" name="nit" id="nit" placeholder="">
                                </div>
                                <div class="col-md-3">
                                    <label for="nrc">NRC</label>
                                    <input type="text" class="form-control form-control-sm"
                                        [(ngModel)]="addressCompany.nrc" name="nrc" id="nrc" placeholder="">
                                </div>
                                <div class="col-md-3">
                                    <label for="address">Dirección</label>
                                    <input type="text" class="form-control form-control-sm"
                                        [(ngModel)]="addressCompany.address" name="address" id="address" placeholder="">
                                </div>
                                <div class="col-md-3">
                                    <label for="department">Departamento</label>
                                    <input type="text" class="form-control form-control-sm"
                                        [(ngModel)]="addressCompany.department" name="department" id="department"
                                        placeholder="">
                                </div>
                                <div class="col-md-3">
                                    <label for="municipality">Municipio</label>
                                    <input type="text" class="form-control form-control-sm"
                                        [(ngModel)]="addressCompany.municipality" name="municipality" id="municipality"
                                        placeholder="">
                                </div>
                                <div class="col-md-3">
                                    <label for="phone">Teléfono</label>
                                    <input type="number" class="form-control form-control-sm"
                                        [(ngModel)]="addressCompany.phone" name="phone" id="phone" placeholder="">
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div *ngIf="user.direction.length > 0" class="col-sm-12">
                <ng-container *ngFor="let item of user.direction; let i = index;">
                    <div class="card text-dark bg-light mb-3">
                        <div class="card-header">
                            <div class="row">
                                <div class="col d-grid align-items-center"><b class="fs-5 text-capitalize">
                                        {{item.type}} Dirección</b></div>
                                <div class="col d-flex justify-content-end">
                                    <button (click)="changeStatus(i)" class="btn mx-1"
                                        [ngClass]="item.is_default ? 'btn-danger' : 'btn-success'"
                                        [innerText]="item.is_default ? 'Deshabilitar Dirección principal' : 'Activar Dirección principal '"></button>
                                    <button (click)="DeleteItem(i,item.is_default)" class="btn btn-outline-danger mx-1">
                                        <i aria-hidden="true" class="fa fa-trash"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div class="card-body">
                            <div class="row">
                                <div class="col-lg-4 col-md-6 col-sm-12">
                                    <div class="col-lg-12 col-md-12 col-sm-12 mb-3">
                                        <label class="form-label">Mapa</label>
                                        <div class="w-100 h-auto">
                                            <div class="h-100 w-100 overflow-hidden position-relative">
                                                <div style="width: 100% !important; height: 15rem !important;"
                                                    #googleMap{{i}} id="googleMap{{i}}"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-8 col-md-6 col-sm-12">
                                    <div class="row my-auto">
                                        <div class="col-lg-3 col-md-4 col-sm-12 mb-3">
                                            <label for="type">decription</label>
                                            <input type="text" class="form-control"
                                                [(ngModel)]="item.type" id="type{{i}}" name="type"
                                                placeholder="address type">
                                        </div>
                                        <div class="col-lg-9 col-md-8 col-sm-12 mb-3">
                                            <label for="direction{{i}}">Address *</label>
                                            <div class="input-group">
                                                <div class="input-group-text">
                                                    <i aria-hidden="true" class="fa fa-search"></i>
                                                </div>
                                                <input type="search" class="form-control" id="search{{i}}"
                                                    (input)="addressChangeDynamic(item, i)" [(ngModel)]="item.direction"
                                                    placeholder="Buscar lugares cercanos" #search{{i}}>
                                                <div class="input-group-text cursor-pointer" (click)="getPositionDynamic(item, i)">
                                                    <i aria-hidden="true" class="fa fa-map-marker"></i>
                                                </div>
                                            </div>
                                            <!-- <input type="text" class="form-control"
                                                [(ngModel)]="item.direction" id="direction{{i}}" name="direction"
                                                placeholder="Address"> -->
                                        </div>
                                        <div class="col-lg-3 col-md-4 col-sm-12 mb-3">
                                            <label for="zipCode">Zip Code *</label>
                                            <input type="text" class="form-control"
                                                [(ngModel)]="item.zipCode" id="zipCode{{i}}" name="zipCode"
                                                placeholder="zip-code">
                                        </div>
                                        <div class="col-lg-3 col-md-4 col-sm-12 mb-3 select_input">
                                            <label for="country">Country *</label>
                                            <select id="country{{i}}" name="country" [(ngModel)]="item.country"
                                                class="form-control" size="1">
                                                <option value="">Select country</option>
                                                <option value="US">US</option>
                                                <option value="El Salvador">El Salvador</option>
                                            </select>
                                        </div>
                                        <div class="col-lg-3 col-md-4 col-sm-12 mb-3">
                                            <label for="city">City *</label>
                                            <input type="text" [(ngModel)]="item.city"
                                                class="form-control" id="city{{i}}" name="city"
                                                placeholder="City">
                                        </div>
                                        <div class="col-lg-3 col-md-4 col-sm-12 mb-3">
                                            <label for="state">Region/State *</label>
                                            <input type="text" class="form-control"
                                                [(ngModel)]="item.state" id="state{{i}}" name="state"
                                                placeholder="Region/state">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </div>
            <div #form *ngIf="showNewAddress || user.direction.length == 0" class="col-lg-12">
                <div class="card text-dark bg-light mb-3">
                    <div class="card-header">
                        <div class="row">
                            <div class="col d-grid align-items-center">
                                <b class="fs-5"> Nueva dirección de envío</b>
                            </div>
                            <div class="col d-flex justify-content-end">
                                <button *ngIf="showBtnSave" class="btn btn-sm btn-solid" (click)="updateUser()"
                                    type="submit">Guardar</button>
                            </div>
                        </div>
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-lg-4 col-md-6 col-sm-12">
                                <div class="col-lg-12 col-md-12 col-sm-12 mb-3">
                                    <label class="form-label">Mapa</label>
                                    <div class="w-100 h-auto">
                                        <div class="h-100 w-100 overflow-hidden position-relative">
                                            <div style="width: 100% !important; height: 15rem !important;" #googleMap
                                                id="googleMap"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-8 col-md-6 col-sm-12">
                                <div class="row my-auto">
                                    <div class="col-lg-3 col-md-4 col-sm-12 mb-3">
                                        <label for="type">decription</label>
                                        <input type="text" class="form-control" [(ngModel)]="address.type" id="type"
                                            name="type" placeholder="address type">
                                    </div>
                                    <div class="col-lg-9 col-md-8 col-sm-12 mb-3">
                                        <label for="direction">Address *</label>
                                        <div class="input-group">
                                            <div class="input-group-text">
                                                <i aria-hidden="true" class="fa fa-search"></i>
                                            </div>
                                            <input type="search" class="form-control" id="direction"
                                                (input)="addressChange()" [(ngModel)]="address.direction"
                                                placeholder="Buscar lugares cercanos" #search>
                                            <div class="input-group-text cursor-pointer" (click)="getPosition()">
                                                <i aria-hidden="true" class="fa fa-map-marker"></i>
                                            </div>
                                        </div>
                                        <!-- <input type="text" class="form-control"
                                            [(ngModel)]="address.direction" id="direction" name="direction"
                                            placeholder="Address"> -->
                                    </div>
                                    <div class="col-lg-3 col-md-4 col-sm-12 mb-3">
                                        <label for="zipCode">Zip Code *</label>
                                        <input type="text" class="form-control" [(ngModel)]="address.zipCode"
                                            id="zipCode" name="zipCode" placeholder="zip-code">
                                    </div>
                                    <div class="col-lg-3 col-md-4 col-sm-12 mb-3 select_input">
                                        <label for="country">Country *</label>
                                        <select id="country" name="country" [(ngModel)]="address.country"
                                            class="form-control" size="1">
                                            <option value="">Select country</option>
                                            <option value="US">US</option>
                                            <option value="El Salvador">El Salvador</option>
                                        </select>
                                    </div>
                                    <div class="col-lg-3 col-md-4 col-sm-12 mb-3">
                                        <label for="city">City *</label>
                                        <input type="text" [(ngModel)]="address.city" class="form-control" id="city"
                                            name="city" placeholder="City">
                                    </div>
                                    <div class="col-lg-3 col-md-4 col-sm-12 mb-3">
                                        <label for="state">Region/State *</label>
                                        <input type="text" class="form-control" [(ngModel)]="address.state" id="state"
                                            name="state" placeholder="Region/state">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- Section ends -->